
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "Pagination",
  emits: ["page"],
  props: {
    information: {
      type: Object,
    },
    showFavorites: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const page: Ref<number> = ref(1);
    const changePage = (target: number): void => {
      page.value = target;
      context.emit("page", target);
    };

    const pagination: Ref<HTMLElement | null> = ref(null);
    const showPagination = (): void => {
      const paginationElement: HTMLElement | null = pagination.value;

      if (paginationElement instanceof HTMLElement) {
        const isActive: boolean = paginationElement.className.includes("is-active");
        paginationElement.className = isActive ? "page-list" : "page-list is-active";
      }
    };

    return {
      page,
      changePage,
      pagination,
      showPagination,
    };
  },
});
