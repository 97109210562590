
import { defineComponent } from "vue";

export default defineComponent({
  name: "Error",
  props: {
    message: {
      type: String,
      default: "Something went wrong",
    },
  },
});
