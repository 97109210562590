
import { defineComponent, Ref, ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { Cookies } from "@/services/enums";
import { getSearchedValueFromUrl } from "@/services/search";
import { getCookieByName, saveCookieByName } from "@/services/cookies";

import Header from "@/components/header.vue";
import Navbar from "@/components/navbar.vue";
import Navchar from "@/components/navchar.vue";
import Characters from "@/components/characters.vue";
import Pagination from "@/components/pagination.vue";
import Error from "@/components/error.vue";

import informationQuery from "@/graphql/information.query.gql";

export default defineComponent({
  name: "App",
  components: {
    Header,
    Navbar,
    Navchar,
    Characters,
    Pagination,
    Error,
  },
  setup() {
    const page: Ref<number> = ref(1);
    const setPage = (target: number): void => {
      page.value = target;
    };

    const showFavorites: Ref<boolean> = ref(getCookieByName(Cookies.SHOW_FAVORITES) === "true");
    const setShowFavorites = (show: boolean): void => {
      showFavorites.value = show;
      saveCookieByName(Cookies.SHOW_FAVORITES, String(show), 30);
    };

    const searchFilter: string = getSearchedValueFromUrl();

    const { result, error } = useQuery(informationQuery, { filter: searchFilter });
    const information = useResult(result, null, (data) => data.characters.info);

    return {
      page,
      setPage,
      showFavorites,
      setShowFavorites,
      information,
      error,
    };
  },
});
