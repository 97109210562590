
import { defineComponent, Ref, ref } from "vue";
import { Search } from "@/services/enums";
import { getSearchedValueFromUrl } from "@/services/search";

export default defineComponent({
  name: "Header",
  props: {
    count: {
      type: Number,
    },
  },
  setup() {
    const search: Ref<HTMLElement | null> = ref(null);

    const goToUrlWithQuery = (): void => {
      const searchElement: HTMLElement | null = search.value;

      if (searchElement instanceof HTMLInputElement) {
        window.location.search = Search.QUERY_SUFFIX_FULL + searchElement.value;
      }
    };

    // Using cleaned search filter to replace input value
    const searchFilter: string = getSearchedValueFromUrl();

    return {
      search,
      goToUrlWithQuery,
      searchFilter,
    };
  },
});
