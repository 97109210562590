
import { computed, defineComponent, reactive } from "vue";
import { getSearchedValueFromUrl } from "@/services/search";

import {
  Character,
  getAllCharacters,
  getAllFavoriteCharacters,
  makeCharacterButtonActiveById,
} from "@/services/characters";

import {
  getAllFavoriteCharactersFromStorage,
  removeFavoriteCharacterFromStorage,
  saveFavoriteCharacterToStorage,
} from "@/services/storage";

import CharacterComponent from "@/components/character.vue";

export default defineComponent({
  name: "Characters",
  components: {
    CharacterComponent,
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    if (props.favorite) {
      const favorites: Array<string> = getAllFavoriteCharactersFromStorage();

      const { data, loading } = getAllFavoriteCharacters(favorites);
      const characters = reactive({ data });

      // Get current local storage array and remove certain character from it
      const deleteFavorite = (id: string): void => {
        removeFavoriteCharacterFromStorage(id);

        const filteredData: Array<Character> = characters.data.filter(
          (character: Character) => character.id !== id
        );

        characters.data = computed(() => filteredData);
      };

      return {
        characters,
        loading,
        deleteFavorite,
      };
    } else {
      const searchFilter: string = getSearchedValueFromUrl();

      const { data, loading } = getAllCharacters(props.page, searchFilter);
      const characters = reactive({ data });

      // Get current local storage array and add new character to that
      const saveFavorite = (id: string): void => {
        saveFavoriteCharacterToStorage(id);

        const favorites: Array<string> = getAllFavoriteCharactersFromStorage();

        if (favorites.includes(id)) {
          makeCharacterButtonActiveById(id);
        }
      };

      return {
        characters,
        loading,
        saveFavorite,
      };
    }
  },
});
