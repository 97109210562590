
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navchar",
  props: {
    favorite: {
      type: Boolean,
      default: false,
    },
  },
});
