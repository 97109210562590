
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navbar",
  emits: ["showFavorites"],
  props: {
    showFavorites: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const setShowFavorites = (show: boolean): void => {
      context.emit("showFavorites", show);
    };

    return {
      setShowFavorites,
    };
  },
});
