import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Navchar = _resolveComponent("Navchar")!
  const _component_Characters = _resolveComponent("Characters")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _createVNode(_component_Error, {
          message: _ctx.error.message
        }, null, 8, ["message"])
      ]))
    : (_ctx.information)
      ? (_openBlock(), _createElementBlock("article", _hoisted_2, [
          _createVNode(_component_Header, {
            count: _ctx.information.count
          }, null, 8, ["count"]),
          _createVNode(_component_Navbar, {
            showFavorites: _ctx.showFavorites,
            onShowFavorites: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShowFavorites($event)))
          }, null, 8, ["showFavorites"]),
          _createVNode(_component_Navchar, { favorite: _ctx.showFavorites }, null, 8, ["favorite"]),
          (_ctx.showFavorites)
            ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
                _createVNode(_component_Characters, { favorite: true })
              ]))
            : (_openBlock(), _createElementBlock("section", _hoisted_4, [
                (_openBlock(), _createBlock(_component_Characters, {
                  key: _ctx.page,
                  page: _ctx.page
                }, null, 8, ["page"]))
              ])),
          _createVNode(_component_Pagination, {
            information: _ctx.information,
            showFavorites: _ctx.showFavorites,
            onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setPage($event)))
          }, null, 8, ["information", "showFavorites"])
        ]))
      : _createCommentVNode("", true)
}