
import { defineComponent, onMounted } from "vue";
import { Characters } from "@/services/enums";
import { getAllFavoriteCharactersFromStorage } from "@/services/storage";
import { makeCharacterButtonActiveById } from "@/services/characters";

export default defineComponent({
  name: "Information",
  emits: ["saveFavorite", "deleteFavorite"],
  props: {
    character: {
      type: Object,
    },
    favorite: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const Enums = { Characters };

    const saveFavorite = (id: string): void => {
      context.emit("saveFavorite", id);
    };

    const deleteFavorite = (id: string): void => {
      context.emit("deleteFavorite", id);
    };

    onMounted((): void => {
      if (props.character && !props.favorite) {
        const favorites: Array<string> = getAllFavoriteCharactersFromStorage();

        if (favorites.includes(props.character.id)) {
          makeCharacterButtonActiveById(props.character.id, true);
        }
      }
    });

    return {
      Enums,
      saveFavorite,
      deleteFavorite,
    };
  },
});
